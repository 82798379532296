/** @format */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal-main {
  position: fixed;
  /* background: rgba(255, 255, 255, 0.6); */
  width: 30%;
  height: 50%;
  top: 25%;
  left: 35%;
  padding-left: 5%;
  padding-right: 5%;
  overflow-y: auto;
  /*transform: translate(-50%, -75%);*/
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
